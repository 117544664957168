import { motion } from 'framer-motion';

// MobileStartButton Component
const MobileStartButton = ({ onClick }) => {
  return (
    <div className="fixed bottom-4 left-4 right-4 z-50 lg:hidden">
      <motion.button
        whileHover={{ scale: 1.05, boxShadow: "0 10px 20px rgba(147, 51, 234, 0.3)" }}
        whileTap={{ scale: 0.98 }}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        onClick={onClick}
        className="w-full px-4 py-3 bg-purple-600 text-white rounded-lg font-medium
          hover:bg-purple-700 transition-all duration-300 shadow-lg shadow-purple-900/20
          flex items-center justify-center gap-2 group"
      >
        <svg 
          className="w-5 h-5 group-hover:scale-110 transition-transform duration-300" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M12 6v6m0 0v6m0-6h6m-6 0H6" 
          />
        </svg>
        <span className="group-hover:tracking-wide transition-all duration-300">
          Start New Test
        </span>
      </motion.button>
    </div>
  );
};

// Desktop floating button
const DesktopStartButton = ({ onClick }) => {
  return (
    <motion.button
      whileHover={{ 
        scale: 1.05, 
        boxShadow: "0 10px 20px rgba(147, 51, 234, 0.3)",
      }}
      whileTap={{ scale: 0.98 }}
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      onClick={onClick}
      className="fixed bottom-8 right-8 z-50 hidden lg:flex px-6 py-4 
        bg-purple-600 text-white rounded-full font-medium
        hover:bg-purple-700 transition-all duration-300 
        shadow-lg shadow-purple-900/20
        items-center gap-3 group"
    >
      <svg 
        className="w-6 h-6 group-hover:scale-110 transition-transform duration-300" 
        fill="none" 
        viewBox="0 0 24 24" 
        stroke="currentColor"
      >
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth={2} 
          d="M12 6v6m0 0v6m0-6h6m-6 0H6" 
        />
      </svg>
      <span className="group-hover:tracking-wide transition-all duration-300">
        Start New Test
      </span>
    </motion.button>
  );
};

export { MobileStartButton, DesktopStartButton };