import { useState, useEffect } from 'react';
import { getPerformanceLevel } from '../components/results/performance/PerformanceHeader/PerformanceTemplates';

export const useResultsData = (testId) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true);
        const studentEmail = sessionStorage.getItem('studentEmail');
        
        if (!testId || !studentEmail) {
          throw new Error('Session information missing');
        }

        const API_URL = import.meta.env.VITE_API_URL;
        
        const responses = await Promise.all([
          fetch(`${API_URL}/results/${testId}`),
          fetch(`${API_URL}/concept-analysis/${testId}`),
          fetch(`${API_URL}/weak-concepts/${testId}`),
          fetch(`${API_URL}/concept-progress`),
          fetch(`${API_URL}/time-analysis/${testId}`),
          fetch(`${API_URL}/misconceptions/${testId}`)
        ]);

        const [
          results,
          conceptAnalysis,
          recommendations,
          progress,
          timeAnalysis,
          misconceptions
        ] = await Promise.all(responses.map(r => r.json()));

        if (!results.success || !results.data?.results?.length) {
          throw new Error(results.message || 'No results found for this test');
        }

        const processedData = {
          results: results.data.results || [],
          summary: results.data.summary || null,
          conceptAnalysis: conceptAnalysis.data || [],
          recommendations: recommendations.data?.recommendations || [],
          conceptProgress: progress.data || [],
          timeAnalysis: timeAnalysis.data || [],
          misconceptions: misconceptions.data || [],
          performanceLevel: getPerformanceLevel(results.data.summary?.accuracy || 0)
        };

        setData(processedData);
        setError('');
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [testId]);

  return { data, loading, error };
};