// components/dashboard/DashboardHeader.jsx
import React from 'react';
import PropTypes from 'prop-types'; 
import { motion } from 'framer-motion';
import { UserProfileMenu } from './UserProfileMenu';

const DashboardHeader = ({ userName, userPicture = null, onLogout }) => {
  return (
    <header className="bg-[#112F3B] shadow-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="h-16 flex items-center justify-between gap-4">
          {/* Left Section - Logo and Title */}
          <div className="flex items-center gap-4">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
            >
              <img 
                src="/MQ_Logo_Tagline.png" 
                alt="Math Quotient" 
                className="h-8 w-auto"
              />
            </motion.div>

            {/* Divider */}
            <div className="h-6 w-px bg-white/10 hidden md:block"></div>

            {/* Title */}
            <motion.h1 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0.1 }}
              className="text-lg font-semibold text-white hidden md:block"
            >
              Student Dashboard
            </motion.h1>
          </div>

          {/* Right Section - User Profile */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <UserProfileMenu 
              userName={userName}
              userPicture={userPicture}
              onLogout={onLogout}
            />
          </motion.div>
        </div>
      </div>
    </header>
  );
};

// Add PropTypes validation
DashboardHeader.propTypes = {
  userName: PropTypes.string.isRequired,
  userPicture: PropTypes.string,
  onLogout: PropTypes.func.isRequired,
};

export default DashboardHeader;