// Base URL Configuration
const getBaseUrl = () => {
    const environment = process.env.NODE_ENV;
    const configuredApiUrl = import.meta.env.VITE_API_URL;
  
    // If VITE_API_URL is explicitly set, use it regardless of environment
    if (configuredApiUrl) {
      return `${configuredApiUrl}`;
    }
  
    switch(environment) {
      case 'production':
        console.warn('VITE_API_URL is not set in production environment');
        // Fallback to local if not set (shouldn't happen in production)
        return 'http://localhost:3000/api';
      
      case 'development':
        // Default Docker development environment
        return 'http://localhost:3000/api';
      
      default:
        // Local development fallback
        return 'http://localhost:3000/api';
    }
  };
  
  // API Configuration
  const apiConfig = {
    baseURL: getBaseUrl(),
    headers: {
      'Content-Type': 'application/json',
      // Add any additional default headers here
    },
    endpoints: {
      startTest: '/start-test',
      questions: '/questions',
      responses: '/responses',
      results: (testId) => `/results/${testId}`,
      checkUser: '/user-questions',
      testHistory: '/test-history' ,
      admin: {
        questions: '/admin/questions',
        updateQuestion: (id) => `/admin/questions/${id}`,
        deleteQuestion: (id) => `/admin/questions/${id}`
      }
    },
    timeout: 10000 // 10 seconds timeout
  };
  
  // Logger Configuration
  const apiLogger = {
    request: (config) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('API Request:', {
          url: config.url,
          method: config.method,
          data: config.data,
          params: config.params,
          baseURL: config.baseURL // Added to help debug connection issues
        });
      }
      return config;
    },
    response: (response) => {
      if (process.env.NODE_ENV !== 'production') {
        console.log('API Response:', {
          url: response.config.url,
          status: response.status,
          data: response.data,
          baseURL: response.config.baseURL // Added to help debug connection issues
        });
      }
      return response;
    },
    error: (error) => {
      const errorDetails = {
        url: error.config?.url,
        baseURL: error.config?.baseURL,
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      };
      
      console.error('API Error:', errorDetails);
      return Promise.reject(error);
    }
  };
  
  export { apiConfig, apiLogger };