// client/src/routes/AppRoutes.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { TestProvider } from '../contexts/TestContext.jsx';
import HomePage from '../pages/HomePage.jsx';
import TestPage from '../pages/TestPage.jsx';
import ResultsPage from '../pages/ResultsPage.jsx';
import Dashboard from '../pages/Dashboard.jsx';
import ContentDashboard from '../components/admin/ContentDashboard';
import ProtectedRoute from './ProtectedRoute.jsx';

const AppRoutes = () => {
  // Check for session and starting new test flag
  const hasSession = sessionStorage.getItem('studentEmail');
  const isStartingNewTest = sessionStorage.getItem('startingNewTest');

  return (
    <TestProvider>
      <Router>
        <Routes>
          {/* Home Route - Show HomePage if starting new test or no session */}
          <Route 
            path="/" 
            element={
              hasSession && !isStartingNewTest ? 
                <Navigate to="/dashboard" replace /> : 
                <HomePage />
            } 
          />
          
          {/* Dashboard Route - Protected, requires email */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute requiresTest={false}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          
          {/* Content Dashboard Route - Protected, requires admin access */}
          <Route
            path="/admin/content"
            element={
              <ProtectedRoute requiresTest={false} requiresAdmin={true}>
                <ContentDashboard />
              </ProtectedRoute>
            }
          />
          
          {/* Test Route - Protected, requires both email and testId */}
          <Route
            path="/test"
            element={
              <ProtectedRoute requiresTest={true}>
                <TestPage />
              </ProtectedRoute>
            }
          />
          
          {/* Results Route - Protected, requires both email and testId */}
          <Route
            path="/results/:testId"
            element={
              <ProtectedRoute requiresTest={true}>
                <ResultsPage />
              </ProtectedRoute>
            }
          />

          {/* Catch all route - Redirect to home */}
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      </Router>
    </TestProvider>
  );
};

export default AppRoutes;