import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

export const TestHistoryCard = ({ test }) => {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    const userPicture = sessionStorage.getItem('userPicture');
    // Store test data in sessionStorage
    sessionStorage.setItem('testId', test.test_id);
    sessionStorage.setItem('studentName', test.student_info.name);
    sessionStorage.setItem('studentGrade', test.student_info.grade);
    sessionStorage.setItem('studentWhatsapp', test.student_info.whatsapp);
    sessionStorage.setItem('studentEmail', test.student_info.email);

    sessionStorage.setItem('viewingHistory', 'true');

    if (userPicture) {
      sessionStorage.setItem('studentPicture', userPicture);
    }

    // Navigate to results page
    navigate(`/results/${test.test_id}`);
  };

  return (
    <motion.div
      whileHover={{ scale: 1.01 }}
      className="bg-white rounded-lg shadow-sm p-4 border border-gray-200 hover:border-purple-200 transition-colors"
    >
      <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
        <div className="flex items-center gap-4">
          <div className="w-12 h-12 bg-purple-50 rounded-lg flex items-center justify-center flex-shrink-0">
            <svg className="w-6 h-6 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
            </svg>
          </div>
          <div>
            <div className="flex items-center gap-2">
              <h3 className="font-semibold text-gray-900">Test #{test.test_id}</h3>
              <span className="text-sm text-gray-500">
                {new Date(test.createdAt).toLocaleDateString()}
              </span>
            </div>
            <div className="flex items-center gap-4 mt-1">
              <span className="text-sm text-gray-600">
                Questions: {test.summary.totalQuestions}
              </span>
              <span className="text-sm text-gray-600">
                Time: {Math.round(test.summary.totalTime / 60)} mins
              </span>
            </div>
          </div>
        </div>
  
        <div className="flex items-center gap-4">
          <div className={`
            px-3 py-1.5 rounded-lg text-sm font-medium flex items-center gap-2
            ${test.summary.accuracy >= 80 ? 'bg-green-100 text-green-800' :
              test.summary.accuracy >= 60 ? 'bg-yellow-100 text-yellow-800' :
              'bg-red-100 text-red-800'}
          `}>
            <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
            </svg>
            {test.summary.accuracy.toFixed(1)}%
          </div>
          
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={handleViewDetails}
            className="px-4 py-1.5 text-sm bg-purple-50 text-purple-600 rounded-lg font-medium
              hover:bg-purple-100 transition-colors flex items-center gap-2"
          >
            View Details
            <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M13 7l5 5m0 0l-5 5m5-5H6" />
            </svg>
          </motion.button>
        </div>
      </div>
    </motion.div>
  );
};