import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// User Profile Menu Component
export const UserProfileMenu = ({ userName, userPicture, onLogout }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="relative">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center gap-3 p-2 rounded-lg 
            hover:bg-purple-700 transition-colors"
        >
          {userPicture ? (
            <img 
              src={userPicture} 
              alt={userName} 
              className="w-8 h-8 rounded-full border-2 border-white/20"
            />
          ) : (
            <div className="w-8 h-8 rounded-full bg-purple-200 flex items-center justify-center">
              <span className="text-sm font-semibold text-purple-700">
                {userName?.[0]?.toUpperCase()}
              </span>
            </div>
          )}
          <div className="hidden md:block text-left">
            <p className="text-sm font-medium text-white">{userName}</p>
          </div>
          <svg 
            className={`w-4 h-4 text-white/70 transition-transform ${isOpen ? 'rotate-180' : ''}`}
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
              d="M19 9l-7 7-7-7" />
          </svg>
        </button>
  
        <AnimatePresence>
          {isOpen && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 z-30"
                onClick={() => setIsOpen(false)}
              />
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="absolute right-0 top-full mt-2 w-56 bg-white rounded-lg shadow-lg py-2 z-40"
              >
                <div className="px-4 py-2 border-b border-gray-100">
                  <p className="text-sm font-medium text-gray-900">{userName}</p>
                </div>
                <div className="px-2 py-2">
                  <motion.button
                    whileHover={{ x: 2 }}
                    onClick={onLogout}
                    className="w-full px-2 py-2 text-left text-sm text-gray-700 
                      hover:bg-red-50 hover:text-red-600 rounded-md transition-colors
                      flex items-center gap-2"
                  >
                    <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                    Sign Out
                  </motion.button>
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    );
  };