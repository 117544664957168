// client/src/components/admin/forms/QuestionOptionsForm.jsx
import React from 'react';
import MathDisplay from '../../common/MathDisplay';

const QuestionOptionsForm = ({ data, onChange }) => {
  // Ensure data has all required properties with default values
  const formData = {
    options: {
      A: '',
      B: '',
      C: '',
      D: '',
      ...data?.options
    },
    correct_answer: data?.correct_answer || 'A',
    concepts: {
      option_a: [],
      option_b: [],
      option_c: [],
      option_d: [],
      ...data?.concepts
    },
    misconceptions: {
      option_a: '',
      option_b: '',
      option_c: '',
      option_d: '',
      ...data?.misconceptions
    }
  };

  const handleOptionChange = (option, value) => {
    onChange({
      ...data,
      options: {
        ...data.options,
        [option]: value
      }
    });
  };

  const handleMisconceptionChange = (option, value) => {
    onChange({
      ...data,
      misconceptions: {
        ...data.misconceptions,
        [`option_${option.toLowerCase()}`]: value
      }
    });
  };

  return (
    <div className="space-y-6">
      {['A', 'B', 'C', 'D'].map(option => (
        <div key={option} className={`p-4 rounded-lg border ${
          formData.correct_answer === option ? 'border-green-500 bg-green-50' : 'border-gray-200'
        }`}>
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-medium">
              Option {option}
              {formData.correct_answer === option && (
                <span className="ml-2 text-green-600 text-sm">(Correct Answer)</span>
              )}
            </h3>
          </div>

          {/* Option Text */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Option Text
            </label>
            <textarea
              value={formData.options[option] || ''}
              onChange={(e) => handleOptionChange(option, e.target.value)}
              className="w-full p-2 border rounded-lg resize-none focus:ring-2 focus:ring-blue-500"
              rows={2}
            />
            <div className="mt-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Preview
              </label>
              <MathDisplay content={formData.options[option] || ''} />
            </div>
          </div>

          {/* Misconception (only for incorrect options) */}
          {formData.correct_answer !== option && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Misconception
              </label>
              <textarea
                value={formData.misconceptions[`option_${option.toLowerCase()}`] || ''}
                onChange={(e) => handleMisconceptionChange(option, e.target.value)}
                className="w-full p-2 border rounded-lg resize-none focus:ring-2 focus:ring-blue-500"
                rows={2}
                placeholder="Describe the misconception this option represents..."
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default QuestionOptionsForm;