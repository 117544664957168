// client/src/routes/ProtectedRoute.jsx
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ADMIN_EMAILS = [
  'sidhartharamana@gmail.com',
  'himanshujainmq@gmail.com',
  'aryanvermamq@gmail.com' 
];

const ProtectedRoute = ({ children, requiresTest = false, requiresAdmin = false }) => {
  const location = useLocation();
  const studentEmail = sessionStorage.getItem('studentEmail');
  const testId = sessionStorage.getItem('testId');
  const isStartingNewTest = sessionStorage.getItem('startingNewTest');

  // Check admin access first if required
  if (requiresAdmin) {
    if (!studentEmail || !ADMIN_EMAILS.includes(studentEmail)) {
      return <Navigate to="/dashboard" replace />;
    }
  }

  // Always require email authentication
  if (!studentEmail) {
    return <Navigate to="/" replace />;
  }

  // Check if route requires active test
  if (requiresTest && !testId) {
    // If starting new test, go to home to show form
    if (isStartingNewTest) {
      return <Navigate to="/" replace />;
    }
    // Otherwise, go to dashboard
    return <Navigate to="/dashboard" replace />;
  }

  // Special handling for dashboard
  if (location.pathname === '/dashboard' && isStartingNewTest) {
    // Clear the startingNewTest flag when accessing dashboard directly
    sessionStorage.removeItem('startingNewTest');
  }

  return children;
};

export default ProtectedRoute;