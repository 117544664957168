import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PrinterIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';

const ActionButton = ({ icon: Icon, label, onClick, variant = 'secondary', className = '' }) => (
  <button
    onClick={onClick}
    className={`
      group relative overflow-hidden
      inline-flex items-center justify-center
      px-8 py-4
      rounded-xl font-semibold text-sm sm:text-base
      transition-all duration-300 ease-in-out
      transform hover:scale-105 active:scale-95
      shadow-md hover:shadow-xl
      ${
        variant === 'primary'
          ? 'bg-gradient-to-r from-purple-600 to-purple-800 text-white hover:from-purple-700 hover:to-purple-900'
          : 'bg-gradient-to-r from-blue-500 to-indigo-600 text-white hover:from-blue-600 hover:to-indigo-700'
      }
      ${className}
    `}
  >
    <span className="absolute inset-0 w-full h-full bg-white/[0.03] transform -skew-x-12 transition-transform group-hover:translate-x-full"></span>
    <Icon className="w-5 h-5 mr-3" />
    <span className="relative">{label}</span>
    {variant !== 'primary' && (
      <SparklesIcon className="w-4 h-4 ml-2 animate-pulse text-yellow-300" />
    )}
  </button>
);

const ActionButtons = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-center items-center mt-8 print:hidden px-4">
        <ActionButton
          icon={PrinterIcon}
          label="Print Report"
          onClick={() => window.print()}
          variant="primary"
        />
      </div>
      
      {/* Fixed Challenge Button */}
      <div className="fixed bottom-0 sm:bottom-6 right-0 sm:right-6 print:hidden z-50 w-full sm:w-auto px-4 sm:px-0 pb-4 sm:pb-0 bg-gradient-to-t from-gray-50 to-transparent pt-4 sm:pt-0 sm:bg-none">
        <ActionButton
          icon={ArrowPathIcon}
          label="Challenge Yourself Again!"
          onClick={() => navigate('/dashboard')}
          className="w-full sm:w-auto rounded-xl sm:rounded-xl"
        />
      </div>

      <div className="mt-6 text-center text-sm text-gray-500 print:hidden sm:hidden">
        <p className="font-medium">Tap sections to expand or collapse details</p>
      </div>
    </>
  );
};

export default ActionButtons;