// GoogleSignIn.jsx
import { motion, AnimatePresence } from 'framer-motion';

export const GoogleSignIn = ({ currentStep, googleAuthenticated, isReturningUser, onGoogleSignIn }) => (
  <AnimatePresence>
    {currentStep === 0 && !googleAuthenticated && !isReturningUser && (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="mt-4 sm:mt-6 text-center"
      >
        <p className="text-xs text-gray-500">
          Already have a Google account?{" "}
          <button
            type="button"
            onClick={onGoogleSignIn}
            className="text-[#7F56D9] hover:underline font-medium"
          >
            Sign in with Google
          </button>
        </p>
      </motion.div>
    )}
  </AnimatePresence>
);