// components/home/StatsCard.jsx
import { motion } from "framer-motion";
export const StatsCard = ({ number, label }) => (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className="p-6 bg-white/5 backdrop-blur-sm rounded-xl border border-white/10"
    >
      <h4 className="text-3xl font-bold bg-gradient-to-r from-white to-white/70 bg-clip-text text-transparent">
        {number}
      </h4>
      <p className="text-white/70 text-sm mt-1">{label}</p>
    </motion.div>
  );