import React, { useState, useEffect } from 'react';
import { Search, Filter, RefreshCw, X } from 'lucide-react';
import adminService from '../../services/adminService';
import QuestionCard from './QuestionCard';
import QuestionEditModal from './QuestionEditModal';
import Pagination from '../common/Pagination';
import MathDisplay from '../common/MathDisplay';
import ContentDashboardHeader from './ContentDashboardHeader';

const ContentDashboard = () => {
  // States for questions and loading
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // States for filtering and pagination
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  
  // States for UI interaction
  const [showFilters, setShowFilters] = useState(false);
  const [expandedQuestions, setExpandedQuestions] = useState(new Set());
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [previewQuestion, setPreviewQuestion] = useState(null);

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, selectedGrade]);

  const fetchQuestions = async () => {
    try {
      setLoading(true);
      const response = await adminService.getAllQuestions();
      if (response.success) {
        setQuestions(response.questions);
      } else {
        setError('Failed to fetch questions');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveQuestion = async (updatedQuestion) => {
    try {
      const response = await adminService.updateQuestion(
        updatedQuestion._id,
        updatedQuestion
      );
      if (response.success) {
        setQuestions(questions.map(q => 
          q._id === updatedQuestion._id ? updatedQuestion : q
        ));
        setEditingQuestion(null);
      }
    } catch (error) {
      setError('Failed to update question');
    }
  };

  const toggleExpand = (questionId) => {
    const newExpanded = new Set(expandedQuestions);
    if (newExpanded.has(questionId)) {
      newExpanded.delete(questionId);
    } else {
      newExpanded.add(questionId);
    }
    setExpandedQuestions(newExpanded);
  };
  
  const handleDelete = async (questionId) => {
    try {
      setLoading(true);
      const response = await adminService.deleteQuestion(questionId);
      if (response.success) {
        setQuestions(questions.filter(q => q._id !== questionId));
        // Optional: Show success message
      }
    } catch (error) {
      console.error('Error deleting question:', error);
      setError('Failed to delete question');
    } finally {
      setLoading(false);
    }
  };

  // Filter and sort questions
  const filteredQuestions = questions.filter(question => {
    const matchesSearch = 
      question.question_text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      question.question_id.toString().includes(searchTerm);
    const matchesGrade = selectedGrade === 'all' || 
      question.difficulty_levels[`grade_${selectedGrade}`] > 0;
    return matchesSearch && matchesGrade;
  });

  // Calculate pagination
  const totalPages = Math.ceil(filteredQuestions.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedQuestions = filteredQuestions.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <ContentDashboardHeader
        userName="Admin"
        onLogout={() => {}}
      />

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-24 py-8">
        {/* Header Stats Card */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-2"> Showing {filteredQuestions.length} questions</h1>
        </div>

        {/* Search and Filters Card */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
          <div className="flex flex-wrap gap-4 items-center justify-between">
            {/* Search Bar */}
            <div className="relative flex-1 min-w-[300px]">
              <Search 
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" 
                size={20} 
              />
              <input
                type="text"
                placeholder="Search questions..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg 
                         focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              />
            </div>

            {/* Action Buttons */}
            <div className="flex gap-4">
              <button
                onClick={() => setShowFilters(!showFilters)}
                className="flex items-center px-4 py-2 text-purple-600 bg-purple-50 
                       rounded-lg hover:bg-purple-100 transition-colors"
              >
                <Filter size={20} className="mr-2" />
                Filters
                {selectedGrade !== 'all' && (
                  <span className="ml-2 px-2 py-0.5 bg-purple-100 text-purple-700 
                               rounded-full text-sm">
                    Grade {selectedGrade}
                  </span>
                )}
              </button>

              <button
                onClick={fetchQuestions}
                className="flex items-center px-4 py-2 text-purple-600 bg-purple-50 
                       rounded-lg hover:bg-purple-100 transition-colors"
              >
                <RefreshCw size={20} className="mr-2" />
                Refresh
              </button>
            </div>
          </div>

          {/* Expanded Filters */}
          {showFilters && (
            <div className="mt-4 pt-4 border-t border-gray-200">
              <div className="flex flex-wrap gap-4">
                <select
                  value={selectedGrade}
                  onChange={(e) => setSelectedGrade(e.target.value)}
                  className="px-4 py-2 border border-gray-300 rounded-lg 
                           focus:ring-2 focus:ring-purple-500"
                >
                  <option value="all">All Grades</option>
                  {[...Array(12)].map((_, i) => (
                    <option key={i + 1} value={i + 1}>Grade {i + 1}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>

        {/* Questions List */}
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin w-16 h-16 border-4 border-purple-600 border-t-transparent rounded-full mb-4"></div>
            <p className="text-gray-600">Loading questions...</p>
          </div>
        ) : error ? (
          <div className="bg-red-50 text-red-600 p-4 rounded-xl shadow-sm">
            <p>Error: {error}</p>
          </div>
        ) : (
          <div className="space-y-6">
            {paginatedQuestions.map(question => (
              <QuestionCard
                key={question._id}
                question={question}
                expanded={expandedQuestions.has(question._id)}
                onToggleExpand={toggleExpand}
                onEdit={() => setEditingQuestion(question)}
                onPreview={() => setPreviewQuestion(question)}
                onDelete={handleDelete}
              />
            ))}
          </div>
        )}

        {/* Pagination */}
        <div className="mt-8">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={setItemsPerPage}
          />
        </div>
      </main>

      {/* Edit Modal */}
      {editingQuestion && (
        <QuestionEditModal
          question={editingQuestion}
          onSave={handleSaveQuestion}
          onClose={() => setEditingQuestion(null)}
        />
      )}

      {/* Preview Modal */}
      {previewQuestion && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 
                     flex items-center justify-center p-4">
          <div className="bg-white rounded-xl w-full max-w-3xl max-h-[90vh] 
                       overflow-y-auto">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-xl font-semibold">Question Preview</h2>
              <button
                onClick={() => setPreviewQuestion(null)}
                className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
              >
                <X size={20} />
              </button>
            </div>
            <div className="p-6">
              <MathDisplay content={previewQuestion.question_text} />
              <div className="grid grid-cols-2 gap-4 mt-4">
                {Object.entries(previewQuestion.options).map(([key, value]) => (
                  <div
                    key={key}
                    className={`p-4 rounded-lg ${
                      previewQuestion.correct_answer === key
                        ? 'bg-green-50 border border-green-200'
                        : 'bg-gray-50 border border-gray-200'
                    }`}
                  >
                    <span className="font-medium">Option {key}:</span>
                    <MathDisplay content={value} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentDashboard;