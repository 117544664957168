// utils/statsCalculator.js
export const calculateStats = (history) => {
    const totalScore = history.reduce((sum, test) => sum + test.summary.accuracy, 0);
    const totalTimeSpent = history.reduce((sum, test) => sum + test.summary.totalTime, 0);
  
    // Calculate trends
    const previousTests = history.slice(Math.max(history.length - 10, 0));
    const currentTests = history.slice(Math.max(history.length - 5, 0));
    
    const previousAvg = previousTests.reduce((sum, test) => sum + test.summary.accuracy, 0) / previousTests.length;
    const currentAvg = currentTests.reduce((sum, test) => sum + test.summary.accuracy, 0) / currentTests.length;
    
    const scoreTrend = Math.round(((currentAvg - previousAvg) / previousAvg) * 100);
  
    return {
      totalTests: history.length,
      averageScore: (totalScore / history.length).toFixed(1),
      totalTime: Math.round(totalTimeSpent / 60),
      trends: {
        tests: 15, // This could be calculated based on test frequency
        score: scoreTrend,
        time: -5 // This could be calculated based on average time per test
      }
    };
  };