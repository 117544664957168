// hooks/useTestHistory.js
import { useState, useCallback } from 'react';
import api from '../services/api';
import { storageService } from '../services/storageService';
import { calculateStats } from '../utils/statsCalculator';

export const useTestHistory = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [testHistory, setTestHistory] = useState([]);
  const [previousGrade, setPreviousGrade] = useState(null);
  const [stats, setStats] = useState({
    totalTests: 0,
    averageScore: 0,
    totalTime: 0,
    trends: { tests: 0, score: 0, time: 0 }
  });

  const fetchTestHistory = useCallback(async () => {
    const userEmail = storageService.getItem('studentEmail');
    
    if (!userEmail) {
      setError('No user email found');
      setLoading(false);
      return;
    }

    try {
      const response = await api.get(`/test-history?email=${encodeURIComponent(userEmail)}`);
      const history = response.data.history || [];
      setTestHistory(history);
      
      if (history.length > 0) {
        const calculatedStats = calculateStats(history);
        setStats(calculatedStats);
      }

      // Fetch previous grade
      const userResponse = await api.get(`/user-questions?email=${encodeURIComponent(userEmail)}`);
      if (userResponse.data.success && userResponse.data.lastGrade) {
        setPreviousGrade(userResponse.data.lastGrade);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load test history');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    error,
    testHistory,
    stats,
    previousGrade,
    fetchTestHistory
  };
};