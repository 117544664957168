// client/src/components/admin/QuestionEditModal.jsx
import React, { useState } from 'react';
import { X } from 'lucide-react';
import MathDisplay from '../common/MathDisplay';
import QuestionBasicForm from './forms/QuestionBasicForm';
import QuestionOptionsForm from './forms/QuestionOptionsForm';
import QuestionConceptsForm from './forms/QuestionConceptsForm';
import QuestionDifficultyForm from './forms/QuestionDifficultyForm';

const TabButton = ({ id, label, currentTab, onClick }) => (
  <button
    type="button"
    onClick={() => onClick(id)}
    className={`px-4 py-2 rounded-lg transition-colors ${
      currentTab === id
        ? 'bg-blue-100 text-blue-700 font-medium'
        : 'text-gray-600 hover:bg-gray-100'
    }`}
  >
    {label}
  </button>
);

const QuestionEditModal = ({ question, onSave, onClose }) => {
  const [formData, setFormData] = useState(question);
  const [currentTab, setCurrentTab] = useState('basic');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const tabs = [
    { id: 'basic', label: 'Basic Info' },
    { id: 'options', label: 'Options' },
    { id: 'concepts', label: 'Concepts' },
    { id: 'difficulty', label: 'Difficulty' }
  ];

  // Handle form data changes without saving
  const handleFormChange = (newData, shouldSave = false) => {
    setFormData(newData);
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    try {
      setIsSubmitting(true);
      await onSave(formData);
      onClose();
    } catch (error) {
      console.error('Error saving question:', error);
      // You might want to show an error message to the user here
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderForm = () => {
    const props = {
      data: formData,
      onChange: handleFormChange
    };

    switch (currentTab) {
      case 'basic':
        return <QuestionBasicForm {...props} />;
      case 'options':
        return <QuestionOptionsForm {...props} />;
      case 'concepts':
        return <QuestionConceptsForm {...props} />;
      case 'difficulty':
        return <QuestionDifficultyForm {...props} />;
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
        {/* Header */}
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-xl font-semibold text-gray-900">Edit Question</h2>
          <button
            type="button"
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded text-gray-500 hover:text-gray-700"
          >
            <X size={20} />
          </button>
        </div>

        {/* Tabs */}
        <div className="border-b bg-gray-50">
          <div className="flex space-x-1 p-2 overflow-x-auto">
            {tabs.map(tab => (
              <TabButton
                key={tab.id}
                {...tab}
                currentTab={currentTab}
                onClick={setCurrentTab}
              />
            ))}
          </div>
        </div>

        {/* Form Content */}
        <div className="flex-1 overflow-y-auto">
          <form onSubmit={handleSubmit} className="h-full">
            <div className="p-4">
              {renderForm()}
            </div>
            
            {/* Preview Section */}
            <div className="p-4 border-t bg-gray-50">
              <h3 className="text-lg font-medium text-gray-900 mb-3">Preview</h3>
              <div className="space-y-4">
                <div className="bg-white p-4 rounded-lg border">
                  <MathDisplay content={formData.question_text} />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {Object.entries(formData.options || {}).map(([key, value]) => (
                    <div
                      key={key}
                      className={`p-3 rounded-lg ${
                        formData.correct_answer === key
                          ? 'bg-green-50 border border-green-200'
                          : 'bg-white border border-gray-200'
                      }`}
                    >
                      <span className="font-medium text-gray-700">Option {key}:</span>
                      <div className="mt-1">
                        <MathDisplay content={value} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Action Buttons - Fixed at bottom */}
            <div className="sticky bottom-0 bg-white border-t px-4 py-3 flex justify-end space-x-2">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-gray-700 bg-white border border-gray-300 
                         rounded-lg hover:bg-gray-50 font-medium"
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-blue-600 rounded-lg 
                         hover:bg-blue-700 font-medium disabled:opacity-50"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Saving...' : 'Save All Changes'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default QuestionEditModal;