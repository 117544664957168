// components/StudentForm/StudentForm.jsx
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { storageService } from '../../services/storageService';
import api from '../../services/api';

import { FormStep } from './FormStep';
import { ProgressIndicator } from './ProgressIndicator';
import { FormHeader } from './FormHeader';
import { FormButtons } from './FormButtons';
import { FormFooter } from './FormFooter';
import { GoogleSignIn } from './GoogleSignIn';
import { ErrorMessage } from './ErrorMessage';
import { FormContainer } from './FormContainer';
import { LoadingSpinner } from './LoadingSpinner';
import NameInput from './NameInput';
import GradeSelect from './GradeSelect';
import WhatsAppInput from './WhatsAppInput';
import EmailInput from './EmailInput';

const INITIAL_FORM_STATE = {
  name: '',
  grade: '',
  whatsapp: '',
  email: '',
  picture: ''
};

const INITIAL_ERROR_STATE = {
  name: '',
  grade: '',
  whatsapp: '',
  email: '',
  general: ''
};

const StudentForm = ({ onSuccess, initialData, googleAuthenticated = false }) => {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isReturningUser, setIsReturningUser] = useState(false);
  const [errors, setErrors] = useState(INITIAL_ERROR_STATE);

  // Initialize form with Google data if available
  useEffect(() => {
    if (initialData) {
      setFormData(prev => ({
        ...prev,
        name: initialData.name || prev.name,
        email: initialData.email || prev.email,
        picture: initialData.picture || prev.picture
      }));

      checkExistingUser(initialData.email);
    }
  }, [initialData]);

  const checkExistingUser = async (email) => {
    try {
      const response = await api.get(`/user-questions?email=${encodeURIComponent(email)}`);
      
      if (response.data.success && response.data.userExists) {
        setIsReturningUser(true);
        if (response.data.whatsapp) {
          setFormData(prev => ({
            ...prev,
            whatsapp: response.data.whatsapp
          }));
        }
      }
    } catch (error) {
      console.error('Error checking user:', error);
      setIsReturningUser(false);
    }
  };

  const validateForm = () => {
    const newErrors = { ...INITIAL_ERROR_STATE };
    let isValid = true;

    if (!formData.grade) {
      newErrors.grade = 'Please select a grade';
      isValid = false;
    }

    if (!isReturningUser && currentStep === getTotalSteps() - 1) {
      if (!formData.whatsapp?.trim()) {
        newErrors.whatsapp = 'WhatsApp number is required';
        isValid = false;
      }

      if (!googleAuthenticated) {
        if (!formData.name?.trim()) {
          newErrors.name = 'Name is required';
          isValid = false;
        }
        if (!formData.email?.trim()) {
          newErrors.email = 'Email is required';
          isValid = false;
        }
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));

    // Clear error for the changed field
    if (errors[field]) {
      setErrors(prev => ({
        ...prev,
        [field]: ''
      }));
    }
  };

  const getTotalSteps = () => {
    if (isReturningUser) return 1;
    return googleAuthenticated ? 2 : 4;
  };

  const handleNext = () => {
    if (validateForm() && currentStep < getTotalSteps() - 1) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0 && !isReturningUser) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    setLoading(true);
    setErrors(prev => ({ ...prev, general: '' }));

    try {
      const requestData = {
        name: formData.name.trim(),
        grade: formData.grade,
        whatsapp: formData.whatsapp,
        email: formData.email.trim().toLowerCase(),
        googleAuthenticated,
        picture: formData.picture,
        isReturningUser
      };

      const response = await api.post('/start-test', requestData);

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to start test');
      }

      // Save user data using storageService
      storageService.setUserData({
        test_id: response.data.test_id,
        name: requestData.name,
        grade: requestData.grade,
        whatsapp: requestData.whatsapp,
        email: requestData.email,
        picture: requestData.picture
      });

      // Set test start data
      storageService.setTestStartData(response.data.test_id);

      onSuccess?.();

    } catch (error) {
      console.error('Error starting test:', error);
      setErrors(prev => ({
        ...prev,
        general: error.message || 'Failed to start test. Please try again.'
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    
    if (!formData.grade) {
      setErrors(prev => ({
        ...prev,
        grade: 'Please select a grade'
      }));
      return;
    }

    if (isReturningUser || currentStep === getTotalSteps() - 1) {
      handleSubmit(e);
    } else {
      handleNext();
    }
  };

  const getButtonText = () => {
    if (loading) return <LoadingSpinner />;
    if (isReturningUser) return 'Start Test';
    return currentStep === getTotalSteps() - 1 ? 'Start Test' : 'Continue';
  };

  const renderFormContent = () => {
    if (isReturningUser) {
      return (
        <FormStep isVisible={true}>
          <GradeSelect
            value={formData.grade}
            onChange={(value) => handleInputChange('grade', value)}
            error={errors.grade}
            disabled={loading}
          />
        </FormStep>
      );
    }

    if (googleAuthenticated) {
      return (
        <>
          <FormStep isVisible={currentStep === 0}>
            <GradeSelect
              value={formData.grade}
              onChange={(value) => handleInputChange('grade', value)}
              error={errors.grade}
              disabled={loading}
            />
          </FormStep>
          <FormStep isVisible={currentStep === 1}>
            <WhatsAppInput
              value={formData.whatsapp}
              onChange={(value) => handleInputChange('whatsapp', value)}
              error={errors.whatsapp}
              disabled={loading}
            />
          </FormStep>
        </>
      );
    }

    return (
      <>
        <FormStep isVisible={currentStep === 0}>
          <NameInput
            value={formData.name}
            onChange={(value) => handleInputChange('name', value)}
            disabled={loading}
            error={errors.name}
          />
        </FormStep>
        <FormStep isVisible={currentStep === 1}>
          <EmailInput
            value={formData.email}
            onChange={(value) => handleInputChange('email', value)}
            error={errors.email}
            disabled={loading}
          />
        </FormStep>
        <FormStep isVisible={currentStep === 2}>
          <GradeSelect
            value={formData.grade}
            onChange={(value) => handleInputChange('grade', value)}
            error={errors.grade}
            disabled={loading}
          />
        </FormStep>
        <FormStep isVisible={currentStep === 3}>
          <WhatsAppInput
            value={formData.whatsapp}
            onChange={(value) => handleInputChange('whatsapp', value)}
            error={errors.whatsapp}
            disabled={loading}
          />
        </FormStep>
      </>
    );
  };

  return (
    <FormContainer>
      <FormHeader 
        googleAuthenticated={googleAuthenticated}
        formData={formData}
        isReturningUser={isReturningUser}
      />

      {!isReturningUser && (
        <ProgressIndicator 
          currentStep={currentStep} 
          totalSteps={getTotalSteps()} 
        />
      )}

      <form onSubmit={handleSubmit} className="space-y-3 sm:space-y-4">
        <ErrorMessage error={errors.general} />

        {renderFormContent()}

        <FormButtons 
          currentStep={currentStep}
          isReturningUser={isReturningUser}
          loading={loading}
          handleBack={handleBack}
          handleButtonClick={handleButtonClick}
          getButtonText={getButtonText}
          totalSteps={getTotalSteps()}
        />

        <FormFooter 
          isReturningUser={isReturningUser}
          currentStep={currentStep}
          totalSteps={getTotalSteps()}
        />
      </form>

      <GoogleSignIn 
        currentStep={currentStep}
        googleAuthenticated={googleAuthenticated}
        isReturningUser={isReturningUser}
        onGoogleSignIn={() => {/* Handle Google sign-in */}}
      />
    </FormContainer>
  );
};

StudentForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    picture: PropTypes.string,
    sub: PropTypes.string
  }),
  googleAuthenticated: PropTypes.bool
};

StudentForm.defaultProps = {
  initialData: null,
  googleAuthenticated: false
};

export default StudentForm;