// ErrorMessage.jsx
import { motion, AnimatePresence } from 'framer-motion';

export const ErrorMessage = ({ error }) => (
  <AnimatePresence>
    {error && (
      <motion.div 
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        className="p-2 sm:p-3 bg-red-50 border border-red-200 rounded-lg"
      >
        <p className="text-xs text-red-600">{error}</p>
      </motion.div>
    )}
  </AnimatePresence>
);