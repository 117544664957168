// Dashboard.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import api from '../services/api';
import { storageService } from '../services/storageService';
import { GradeSelectionModal } from '../components/dashboard/GradeModal';
import { MobileStartButton, DesktopStartButton } from '../components/dashboard/ActionButtons';
import { TestHistoryCard } from '../components/dashboard/TestHistoryCard';
import { StatCard } from '../components/dashboard/StatCard';
import DashboardHeader from '../components/dashboard/DashboardHeader';
import { useTestHistory } from '../hooks/useTestHistory';
import PropTypes from 'prop-types';

DashboardHeader.propTypes = {
  userName: PropTypes.string.isRequired,
  userPicture: PropTypes.string,
  onLogout: PropTypes.func.isRequired,
};

// Stats Icons
const statsIcons = {
  tests: (
    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
    </svg>
  ),
  score: (
    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
        d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
    </svg>
  ),
  time: (
    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  )
};

const LoadingState = () => (
  <div className="min-h-screen bg-gray-50 flex items-center justify-center">
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="text-center"
    >
      <div className="animate-spin w-16 h-16 border-4 border-purple-600 border-t-transparent rounded-full mb-4"></div>
      <p className="text-gray-600">Loading your dashboard...</p>
    </motion.div>
  </div>
);

const EmptyTestHistory = ({ onStartTest }) => (
  <div className="bg-white rounded-xl shadow-sm p-8 text-center">
    <div className="flex flex-col items-center max-w-md mx-auto">
      <div className="w-16 h-16 bg-purple-50 rounded-full flex items-center justify-center mb-4">
        <svg className="w-8 h-8 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
        </svg>
      </div>
      <h3 className="text-lg font-medium text-gray-900 mb-2">No Tests Yet</h3>
      <p className="text-gray-500 mb-4">Ready to start your first test? Click the button above to begin!</p>
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={onStartTest}
        className="px-4 py-2 bg-purple-600 text-white rounded-lg font-medium
          hover:bg-purple-700 transition-colors shadow-lg shadow-purple-900/10
          flex items-center gap-2"
      >
        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
        Start Your First Test
      </motion.button>
    </div>
  </div>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const [showGradeModal, setShowGradeModal] = useState(false);
  
  const {
    loading,
    error,
    testHistory,
    stats,
    previousGrade,
    fetchTestHistory
  } = useTestHistory();

  useEffect(() => {
    // Clear the startingNewTest flag when mounting dashboard
    storageService.removeItem('startingNewTest');

    // Check authentication
    if (!storageService.isAuthenticated()) {
      navigate('/');
      return;
    }

    fetchTestHistory();
  }, [navigate, fetchTestHistory]);

  const handleStartNewTest = async (selectedGrade) => {
    try {
      // Keep only essential session data
      storageService.keepEssentialData();

      const userData = storageService.getUserData();
      const response = await api.get(`/user-questions?email=${encodeURIComponent(userData.studentEmail)}`);
      
      if (!response.data.success) {
        throw new Error('Failed to fetch user data');
      }

      const startTestResponse = await api.post('/start-test', {
        name: userData.studentName,
        grade: selectedGrade,
        whatsapp: response.data.whatsapp,
        email: userData.studentEmail,
        isReturningUser: true
      });

      if (!startTestResponse.data.success) {
        throw new Error('Failed to start test');
      }

      // Save test data
      storageService.setUserData({
        test_id: startTestResponse.data.test_id,
        ...startTestResponse.data.student_info
      });
      
      navigate('/test');
    } catch (error) {
      console.error('Error starting new test:', error);
      storageService.setItem('startingNewTest', 'true');
      navigate('/');
    }
  };

  const handleLogout = () => {
    storageService.clearSession();
    navigate('/');
  };

  if (loading) {
    return <LoadingState />;
  }

  const userData = storageService.getUserData();

  return (
    <div className="min-h-screen bg-gray-50">
      <DashboardHeader
        userName={userData.studentName}
        userPicture={userData.studentPicture}
        onLogout={handleLogout}
      />

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-24 py-8">
        {/* Stats Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          <StatCard
            label="Total Tests"
            value={stats.totalTests}
            trend={stats.trends.tests}
            icon={statsIcons.tests}
          />
          <StatCard
            label="Average Score"
            value={`${stats.averageScore}%`}
            trend={stats.trends.score}
            icon={statsIcons.score}
          />
          <StatCard
            label="Total Time"
            value={`${stats.totalTime} mins`}
            trend={stats.trends.time}
            icon={statsIcons.time}
          />
        </div>

        {/* Recent Tests Section */}
        <div className="space-y-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold text-gray-900">Test History</h2>
            <div className="text-sm text-gray-500">
              Total Tests: {testHistory.length}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4">
            {testHistory.length > 0 ? (
              testHistory.map((test, index) => (
                <TestHistoryCard key={test.test_id || index} test={test} />
              ))
            ) : (
              <EmptyTestHistory onStartTest={() => setShowGradeModal(true)} />
            )}
          </div>
        </div>
      </main>

      {/* Action Buttons */}
      <MobileStartButton onClick={() => setShowGradeModal(true)} />
      <DesktopStartButton onClick={() => setShowGradeModal(true)} />

      {/* Grade Selection Modal */}
      {showGradeModal && (
        <GradeSelectionModal
          onClose={() => setShowGradeModal(false)}
          onSubmit={(grade) => {
            setShowGradeModal(false);
            handleStartNewTest(grade);
          }}
          previousGrade={previousGrade}
        />
      )}
    </div>
  );
};

export default Dashboard;