// client/src/services/adminService.js
import api from './api';
import { apiConfig } from '../config/api.config';

class AdminService {
  async getAllQuestions() {
    try {
      console.log('Fetching questions from:', apiConfig.baseURL + apiConfig.endpoints.admin.questions);
      const response = await api.get(apiConfig.endpoints.admin.questions);
      return {
        success: true,
        questions: response.data.questions || []
      };
    } catch (error) {
      console.error('Error fetching questions:', error);
      return {
        success: false,
        error: error.message || 'Failed to fetch questions'
      };
    }
  }

  async updateQuestion(questionId, questionData) {
    try {
      const response = await api.put(
        apiConfig.endpoints.admin.updateQuestion(questionId),
        questionData
      );
      return {
        success: true,
        question: response.data.question
      };
    } catch (error) {
      console.error('Error updating question:', error);
      return {
        success: false,
        error: error.message || 'Failed to update question'
      };
    }
  }

  async deleteQuestion(questionId) {
    try {
      const response = await api.delete(
        apiConfig.endpoints.admin.deleteQuestion(questionId)
      );
      return {
        success: true,
        message: response.data.message || 'Question deleted successfully'
      };
    } catch (error) {
      console.error('Error deleting question:', error);
      return {
        success: false,
        error: error.message || 'Failed to delete question'
      };
    }
  }

  // Helper method to validate responses
  handleResponse(response) {
    if (!response.data) {
      throw new Error('Invalid response format');
    }
    return response.data;
  }

  // Helper method to format errors
  handleError(error) {
    const message = error.response?.data?.message || error.message || 'An error occurred';
    return {
      success: false,
      error: message
    };
  }
}

const adminService = new AdminService();
export default adminService;