import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

export const GradeSelectionModal = ({ onClose, onSubmit, previousGrade }) => {
    const [selectedGrade, setSelectedGrade] = useState(previousGrade || '');
    
    const grades = [
      { value: 'G5', label: 'Grade 5', category: 'Primary' },
      { value: 'G6', label: 'Grade 6', category: 'Primary' },
      { value: 'G7', label: 'Grade 7', category: 'Middle' },
      { value: 'G8', label: 'Grade 8', category: 'Middle' },
      { value: 'G9', label: 'Grade 9', category: 'High' },
      { value: 'G10', label: 'Grade 10', category: 'High' },
      { value: 'G11', label: 'Grade 11', category: 'High' },
      { value: 'G12', label: 'Grade 12', category: 'High' }
    ];
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (selectedGrade) {
        onSubmit(selectedGrade);
      }
    };
  
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          className="bg-white rounded-xl shadow-xl p-6 w-full max-w-md mx-4"
        >
          <form onSubmit={handleSubmit}>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Select Your Grade</h2>
            
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Grade Level
              </label>
              <select
                value={selectedGrade}
                onChange={(e) => setSelectedGrade(e.target.value)}
                className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 
                  focus:border-purple-500 focus:ring-2 focus:ring-purple-200 
                  transition-all duration-200"
                required
              >
                <option value="">Select Grade</option>
                {grades.map(grade => (
                  <option key={grade.value} value={grade.value}>
                    {grade.label}
                  </option>
                ))}
              </select>
            </div>
  
            <div className="flex gap-3">
              <button
                type="button"
                onClick={onClose}
                className="flex-1 px-4 py-2 text-gray-700 bg-gray-100 rounded-lg
                  hover:bg-gray-200 transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!selectedGrade}
                className="flex-1 px-4 py-2 text-white bg-purple-600 rounded-lg
                  hover:bg-purple-700 transition-colors disabled:opacity-50 
                  disabled:cursor-not-allowed"
              >
                Start Test
              </button>
            </div>
          </form>
        </motion.div>
      </div>
    );
  };