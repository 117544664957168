// FormButtons.jsx
import { motion } from 'framer-motion';

export const FormButtons = ({ 
  currentStep,
  isReturningUser,
  loading,
  handleBack,
  handleButtonClick,
  getButtonText,
  totalSteps 
}) => (
  <div className="flex justify-between gap-2 sm:gap-3 mt-4">
    {currentStep > 0 && !isReturningUser && (
      <motion.button
        type="button"
        onClick={handleBack}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="px-3 sm:px-4 py-2.5 text-[#7F56D9] border border-[#7F56D9] rounded-lg
          hover:bg-[#7F56D9]/5 transition-colors duration-300 text-sm"
      >
        Back
      </motion.button>
    )}
    
    <motion.button
      type={isReturningUser || currentStep === totalSteps - 1 ? 'submit' : 'button'}
      onClick={handleButtonClick}
      disabled={loading}
      whileHover={{ scale: loading ? 1 : 1.02 }}
      whileTap={{ scale: loading ? 1 : 0.98 }}
      className={`flex-1 py-2.5 px-3 sm:px-4 rounded-lg text-white font-medium text-sm
        transition-all duration-300 relative overflow-hidden
        ${loading 
          ? 'bg-[#7F56D9]/60 cursor-not-allowed' 
          : 'bg-[#7F56D9] hover:bg-[#7F56D9]/90'
        }
        shadow-sm sm:shadow-md shadow-[#7F56D9]/20`}
    >
      {getButtonText()}
    </motion.button>
  </div>
);