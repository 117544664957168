// client/src/components/admin/forms/QuestionBasicForm.jsx
import React from 'react';
import MathDisplay from '../../common/MathDisplay';

const QuestionBasicForm = ({ data, onChange }) => {
  const handleChange = (field, value) => {
    onChange({ ...data, [field]: value });
  };

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Question ID
        </label>
        <input
          type="text"
          value={data.question_id}
          onChange={(e) => handleChange('question_id', e.target.value)}
          className="w-full p-2 border rounded-lg"
          disabled
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Question Text
        </label>
        <textarea
          value={data.question_text}
          onChange={(e) => handleChange('question_text', e.target.value)}
          rows={4}
          className="w-full p-2 border rounded-lg resize-none focus:ring-2 focus:ring-blue-500"
        />
        <div className="mt-2">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Preview
          </label>
          <MathDisplay content={data.question_text} />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Correct Answer
        </label>
        <select
          value={data.correct_answer}
          onChange={(e) => handleChange('correct_answer', e.target.value)}
          className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
        >
          {['A', 'B', 'C', 'D'].map(option => (
            <option key={option} value={option}>
              Option {option}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default QuestionBasicForm;