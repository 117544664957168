// client/src/services/api.js
import axios from 'axios';
import { apiConfig, apiLogger } from '../config/api.config';

const api = axios.create(apiConfig);

// Add request interceptor
api.interceptors.request.use(apiLogger.request);

// Add response interceptor
api.interceptors.response.use(apiLogger.response, apiLogger.error);

export const testService = {
  checkExistingUser: async (email) => {
    const response = await api.get(apiConfig.endpoints.checkUser, {
      params: { email }
    });
    return response.data;
  },

  startTest: async (studentInfo) => {
    const response = await api.post(apiConfig.endpoints.startTest, studentInfo);
    return response.data;
  },

  getQuestions: async (grade) => {
    const response = await api.get(apiConfig.endpoints.questions, { 
      params: { grade }
    });
    return response.data;
  },

  submitResponse: async (responseData) => {
    const response = await api.post(apiConfig.endpoints.responses, responseData);
    return response.data;
  },

  getTestResults: async (testId) => {
    const response = await api.get(apiConfig.endpoints.results(testId));
    return response.data;
  },
  
  getTestHistory: async (email) => {
    const response = await api.get(apiConfig.endpoints.testHistory, {
      params: { email }
    });
    return response.data;
  }
};

export default api;