// client/src/components/admin/QuestionCard.jsx
import React, { useState } from 'react';
import { Edit2, ChevronDown, ChevronUp, Eye, Trash2 } from 'lucide-react';
import MathDisplay from '../common/MathDisplay';

const DifficultyBadge = ({ level }) => {
  const colors = {
    0: 'bg-gray-100 text-gray-600',
    1: 'bg-green-100 text-green-700',
    2: 'bg-blue-100 text-blue-700',
    3: 'bg-yellow-100 text-yellow-700',
    4: 'bg-orange-100 text-orange-700',
    5: 'bg-red-100 text-red-700'
  };

  const labels = {
    0: 'N/A',
    1: 'Very Easy',
    2: 'Easy',
    3: 'Medium',
    4: 'Hard',
    5: 'Very Hard'
  };

  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${colors[level] || colors[0]}`}>
      {labels[level] || labels[0]}
    </span>
  );
};

const ConceptsList = ({ concepts = [] }) => (
  <div className="flex flex-wrap gap-2">
    {concepts.map((concept, index) => (
      <span 
        key={index}
        className="inline-flex items-center px-2 py-1 rounded-md text-xs 
                   bg-blue-50 text-blue-700 border border-blue-200"
      >
        {concept}
      </span>
    ))}
  </div>
);

const QuestionCard = ({
  question,
  onEdit,
  onPreview,
  onDelete,
  expanded,
  onToggleExpand
}) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  // Ensure question has all required properties with default values
  const safeQuestion = {
    _id: question?._id || '',
    question_id: question?.question_id || '',
    question_text: question?.question_text || '',
    options: question?.options || { A: '', B: '', C: '', D: '' },
    correct_answer: question?.correct_answer || 'A',
    difficulty_levels: question?.difficulty_levels || {},
    concepts: {
      correct_option: [],
      option_a: [],
      option_b: [],
      option_c: [],
      option_d: [],
      ...(question?.concepts || {})
    },
    misconceptions: {
      option_a: '',
      option_b: '',
      option_c: '',
      option_d: '',
      ...(question?.misconceptions || {})
    }
  };

  // Helper function to get active grades
  const getActiveGrades = () => {
    return Object.entries(safeQuestion.difficulty_levels)
      .filter(([_, level]) => level > 0)
      .map(([grade, level]) => ({
        grade: grade.replace('grade_', ''),
        level
      }));
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow">
      {/* Header Section */}
      <div className="p-4 flex items-start justify-between">
        <div className="flex-1">
          <div className="flex items-center space-x-2 mb-2">
            <span className="px-2 py-1 bg-gray-100 text-gray-700 rounded text-sm font-medium">
              ID: {safeQuestion.question_id}
            </span>
            {getActiveGrades().length > 0 && (
              <span className="text-sm text-gray-500">
                Active in {getActiveGrades().length} grades
              </span>
            )}
          </div>
          <div className="mb-4">
            <MathDisplay content={safeQuestion.question_text} />
          </div>
        </div>
        
        <div className="flex items-center space-x-2 ml-4">
          {onPreview && (
            <button
              onClick={() => onPreview(safeQuestion)}
              className="p-2 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-colors"
              title="Preview Question"
            >
              <Eye size={20} />
            </button>
          )}
          {onEdit && (
            <button
              onClick={() => onEdit(safeQuestion)}
              className="p-2 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-colors"
              title="Edit Question"
            >
              <Edit2 size={20} />
            </button>
          )}
          <button
            onClick={() => setShowDeleteConfirm(true)}
            className="p-2 text-gray-600 hover:text-red-600 hover:bg-red-50 rounded-full transition-colors"
            title="Delete Question"
          >
            <Trash2 size={20} />
          </button>
          <button
            onClick={() => onToggleExpand(safeQuestion._id)}
            className="p-2 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-colors"
            title={expanded ? "Show Less" : "Show More"}
          >
            {expanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </button>
        </div>
      </div>

      {/* Options Grid - Always visible */}
      <div className="px-4 pb-4 grid grid-cols-1 md:grid-cols-2 gap-3">
        {Object.entries(safeQuestion.options).map(([key, value]) => (
          <div
            key={key}
            className={`p-3 rounded-lg ${
              safeQuestion.correct_answer === key
                ? 'bg-green-50 border border-green-200'
                : 'bg-gray-50 border border-gray-100'
            }`}
          >
            <div className="flex items-center justify-between mb-1">
              <span className="font-medium text-gray-700">Option {key}</span>
              {safeQuestion.correct_answer === key && (
                <span className="text-green-600 text-sm">Correct Answer</span>
              )}
            </div>
            <MathDisplay content={value} />
          </div>
        ))}
      </div>

      {/* Expanded Content */}
      {expanded && (
        <div className="p-4 border-t">
          {/* Difficulty Levels */}
          <div className="mb-6">
            <h4 className="text-sm font-medium text-gray-700 mb-2">Difficulty Levels</h4>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-3">
              {getActiveGrades().map(({ grade, level }) => (
                <div key={grade} className="flex items-center justify-between p-2 bg-gray-50 rounded">
                  <span className="text-sm text-gray-600">Grade {grade}</span>
                  <DifficultyBadge level={level} />
                </div>
              ))}
            </div>
          </div>

          {/* Concepts */}
          <div className="mb-6">
            <h4 className="text-sm font-medium text-gray-700 mb-2">Concepts</h4>
            <div className="space-y-3">
              <div>
                <span className="text-sm text-gray-600 block mb-1">Correct Answer:</span>
                <ConceptsList concepts={safeQuestion.concepts.correct_option} />
              </div>
              {Object.entries(safeQuestion.concepts)
                .filter(([key]) => key !== 'correct_option')
                .map(([key, concepts]) => (
                  <div key={key}>
                    <span className="text-sm text-gray-600 block mb-1">
                      Option {key.replace('option_', '').toUpperCase()}:
                    </span>
                    <ConceptsList concepts={concepts} />
                  </div>
                ))}
            </div>
          </div>

          {/* Misconceptions */}
          {Object.values(safeQuestion.misconceptions).some(value => value) && (
            <div>
              <h4 className="text-sm font-medium text-gray-700 mb-2">Misconceptions</h4>
              <div className="space-y-2">
                {Object.entries(safeQuestion.misconceptions)
                  .filter(([_, value]) => value)
                  .map(([key, value]) => (
                    <div key={key} className="p-2 bg-gray-50 rounded">
                      <span className="text-sm font-medium text-gray-600">
                        Option {key.replace('option_', '').toUpperCase()}:
                      </span>
                      <p className="text-sm text-gray-600 mt-1">{value}</p>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
      {showDeleteConfirm && (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Delete Question
        </h3>
        <p className="text-gray-600 mb-6">
          Are you sure you want to delete this question? This action cannot be undone.
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={() => setShowDeleteConfirm(false)}
            className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              onDelete(safeQuestion._id);
              setShowDeleteConfirm(false);
            }}
            className="px-4 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  )}
    </div>
  );
};

export default QuestionCard;