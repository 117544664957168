// services/storageService.js

const USER_KEYS = {
    TEST_ID: 'testId',
    STUDENT_NAME: 'studentName',
    STUDENT_GRADE: 'studentGrade',
    STUDENT_WHATSAPP: 'studentWhatsapp',
    STUDENT_EMAIL: 'studentEmail',
    STUDENT_PICTURE: 'studentPicture',
    START_TIME: 'startTime',
    VIEWING_HISTORY: 'viewingHistory',
    STARTING_NEW_TEST: 'startingNewTest'
  };
  
  class StorageService {
    // Set individual item
    setItem(key, value) {
      if (value) {
        sessionStorage.setItem(key, value);
      }
    }
  
    // Get individual item
    getItem(key) {
      return sessionStorage.getItem(key);
    }
  
    // Remove individual item
    removeItem(key) {
      sessionStorage.removeItem(key);
    }
  
    // Set multiple items
    setUserData(userData) {
      const mappings = {
        test_id: USER_KEYS.TEST_ID,
        name: USER_KEYS.STUDENT_NAME,
        grade: USER_KEYS.STUDENT_GRADE,
        whatsapp: USER_KEYS.STUDENT_WHATSAPP,
        email: USER_KEYS.STUDENT_EMAIL,
        picture: USER_KEYS.STUDENT_PICTURE
      };
  
      Object.entries(userData).forEach(([key, value]) => {
        if (mappings[key] && value) {
          this.setItem(mappings[key], value);
        }
      });
    }
  
    // Get user data as an object
    getUserData() {
      return {
        testId: this.getItem(USER_KEYS.TEST_ID),
        studentName: this.getItem(USER_KEYS.STUDENT_NAME),
        studentGrade: this.getItem(USER_KEYS.STUDENT_GRADE),
        studentWhatsapp: this.getItem(USER_KEYS.STUDENT_WHATSAPP),
        studentEmail: this.getItem(USER_KEYS.STUDENT_EMAIL),
        studentPicture: this.getItem(USER_KEYS.STUDENT_PICTURE)
      };
    }
  
    // Clear all session data
    clearSession() {
      sessionStorage.clear();
    }
  
    // Clear specific keys
    clearUserData() {
      Object.values(USER_KEYS).forEach(key => {
        this.removeItem(key);
      });
    }
  
    // Keep only essential data
    keepEssentialData() {
      const keysToKeep = [
        USER_KEYS.STUDENT_EMAIL,
        USER_KEYS.STUDENT_NAME,
        USER_KEYS.STUDENT_PICTURE
      ];
      
      const temp = {};
      keysToKeep.forEach(key => {
        temp[key] = this.getItem(key);
      });
      
      this.clearSession();
      
      Object.entries(temp).forEach(([key, value]) => {
        if (value) {
          this.setItem(key, value);
        }
      });
    }
  
    // Check if user is authenticated
    isAuthenticated() {
      return !!this.getItem(USER_KEYS.STUDENT_EMAIL);
    }
  
    // Set test start data
    setTestStartData(testId, startTime = new Date().toISOString()) {
      this.setItem(USER_KEYS.TEST_ID, testId);
      this.setItem(USER_KEYS.START_TIME, startTime);
    }
  }
  
  export const storageService = new StorageService();
  export { USER_KEYS };