// FormContainer.jsx
import { motion } from 'framer-motion';

export const FormContainer = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3 }}
    className="w-full bg-white rounded-xl shadow-lg p-3 sm:p-6 backdrop-blur-sm relative overflow-hidden"
  >
    <div className="absolute top-0 right-0 w-full h-1 sm:h-1.5 bg-gradient-to-r from-[#7F56D9] via-[#EA4B0D] to-[#112F3B]" />
    {children}
  </motion.div>
);