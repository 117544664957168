// ProgressIndicator.jsx
import { motion } from 'framer-motion';

export const ProgressIndicator = ({ currentStep, totalSteps }) => (
  <div className="flex gap-2 justify-center mb-3 sm:mb-4">
    {[...Array(totalSteps)].map((_, index) => (
      <motion.div
        key={index}
        className={`h-1 sm:h-1.5 rounded-full ${
          index <= currentStep 
            ? 'bg-gradient-to-r from-[#7F56D9] to-[#EA4B0D]' 
            : 'bg-gray-200'
        }`}
        style={{ width: `${100 / totalSteps}%` }}
        initial={{ scale: 0.8 }}
        animate={{ scale: index === currentStep ? 1.1 : 1 }}
        transition={{ duration: 0.3 }}
      />
    ))}
  </div>
);