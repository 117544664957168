// FormFooter.jsx
import { motion, AnimatePresence } from 'framer-motion';

export const FormFooter = ({ isReturningUser, currentStep, totalSteps }) => (
  <AnimatePresence>
    {(isReturningUser || currentStep === totalSteps - 1) && (
      <motion.div 
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 10 }}
        className="mt-3 sm:mt-4 space-y-2 sm:space-y-3"
      >
        <div className="flex items-center justify-center gap-2 text-center">
          <svg className="w-4 h-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <p className="text-xs text-gray-500">
            Make sure you have a stable internet connection
          </p>
        </div>
        
        <motion.p 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="text-xs text-center text-gray-400"
        >
          By starting the test, you agree to our Terms of Service and Privacy Policy
        </motion.p>
      </motion.div>
    )}
  </AnimatePresence>
);