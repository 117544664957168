// components/home/FeatureCard.jsx
import { motion } from 'framer-motion';

export const FeatureCard = ({ icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    className="bg-white/10 backdrop-blur-sm rounded-xl p-6 border border-white/10 hover:border-white/20 transition-colors cursor-pointer"
  >
    <div className="flex items-start gap-4">
      <div className="text-white/90 bg-white/10 p-3 rounded-lg">
        {icon}
      </div>
      <div>
        <h3 className="text-white font-semibold mb-2">{title}</h3>
        <p className="text-white/70 text-sm leading-relaxed">{description}</p>
      </div>
    </div>
  </motion.div>
);