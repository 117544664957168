// FormHeader.jsx
import { motion } from 'framer-motion';

export const FormHeader = ({ googleAuthenticated, formData, isReturningUser }) => (
  <div className="text-center mb-3 sm:mb-4">
    {googleAuthenticated && formData.picture ? (
      <motion.div 
        className="flex flex-col items-center gap-2"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
      >
        <div className="relative">
          <motion.div
            className="absolute -inset-1 bg-gradient-to-r from-[#7F56D9] to-[#EA4B0D] rounded-full blur-sm"
            animate={{ rotate: 360 }}
            transition={{ duration: 8, repeat: Infinity, ease: "linear" }}
          />
          <img 
            src={formData.picture} 
            alt={formData.name} 
            className="relative w-20 h-20 sm:w-20 sm:h-20 rounded-full shadow-lg border-2 border-white"
          />
        </div>
        <div>
          <h3 className="text-sm sm:text-base font-semibold text-[#112F3B]">
            {isReturningUser ? `Welcome back, ${formData.name}!` : formData.name}
          </h3>
          <p className="text-xs text-gray-600">{formData.email}</p>
        </div>
      </motion.div>
    ) : (
      <>
        <motion.h2 
          className="text-lg sm:text-xl font-bold text-[#112F3B]"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
        >
          Welcome to BMI
        </motion.h2>
        <motion.h3 
          className="text-base sm:text-lg font-semibold text-[#112F3B]"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.1 }}
        >
          Bureau of Math Investigation
        </motion.h3>
      </>
    )}
  </div>
);