import { motion } from 'framer-motion';


// Stats Card Component
export const StatCard = ({ label, value, icon, trend }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className="bg-white rounded-xl shadow-sm p-6 border border-gray-200 hover:border-purple-200 transition-colors"
  >
    <div className="flex items-start justify-between">
      <div className="flex items-center gap-4">
        <div className="p-3 bg-purple-50 text-purple-600 rounded-lg">
          {icon}
        </div>
        <div>
          <p className="text-sm text-gray-500">{label}</p>
          <p className="text-2xl font-bold text-gray-900">{value}</p>
        </div>
      </div>
      {trend && (
        <div className={`px-2 py-1 rounded-full text-xs font-medium flex items-center gap-1
          ${trend > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}
        >
          {trend > 0 ? (
            <svg className="w-3 h-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
            </svg>
          ) : (
            <svg className="w-3 h-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
            </svg>
          )}
          {trend > 0 ? '+' : ''}{trend}%
        </div>
      )}
    </div>
  </motion.div>
);