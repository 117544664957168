// client/src/components/admin/forms/QuestionDifficultyForm.jsx
import React from 'react';
import { HelpCircle } from 'lucide-react';

const DifficultyLevel = ({ level, isSelected, onClick }) => {
  const baseClasses = "w-12 h-12 rounded-lg flex items-center justify-center font-medium transition-all";
  const selectedClasses = isSelected 
    ? "bg-blue-600 text-white ring-2 ring-blue-300 ring-offset-2" 
    : "bg-gray-100 text-gray-600 hover:bg-gray-200";

  return (
    <button
      type="button"  // Add this to prevent form submission
      className={`${baseClasses} ${selectedClasses}`}
      onClick={() => onClick(level)}
    >
      {level}
    </button>
  );
};

const GradeDifficultySelector = ({ grade, value, onChange }) => {
  const levels = [0, 1, 2, 3, 4, 5];

  return (
    <div className="p-4 border rounded-lg">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-medium text-gray-900">Grade {grade}</h3>
        <div className="relative group">
          <HelpCircle size={20} className="text-gray-400 cursor-help" />
          <div className="absolute right-0 w-64 p-2 bg-white border rounded-lg shadow-lg 
                        opacity-0 group-hover:opacity-100 transition-opacity z-10
                        invisible group-hover:visible">
            <p className="text-sm text-gray-600">
              0: Not suitable
              <br />1: Very Easy
              <br />2: Easy
              <br />3: Medium
              <br />4: Hard
              <br />5: Very Hard
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-6 gap-2">
        {levels.map(level => (
          <DifficultyLevel
            key={level}
            level={level}
            isSelected={value === level}
            onClick={() => onChange(grade, level)}
          />
        ))}
      </div>

      <p className="mt-3 text-sm text-gray-600">
        {value === 0 && "Not suitable for this grade"}
        {value === 1 && "Very Easy - Basic concept application"}
        {value === 2 && "Easy - Direct application of concepts"}
        {value === 3 && "Medium - Multiple concept application"}
        {value === 4 && "Hard - Complex problem solving"}
        {value === 5 && "Very Hard - Advanced problem solving"}
      </p>
    </div>
  );
};

const QuestionDifficultyForm = ({ data, onChange }) => {
  const handleDifficultyChange = (grade, level) => {
    onChange({
      ...data,
      difficulty_levels: {
        ...data.difficulty_levels,
        [`grade_${grade}`]: level
      }
    }, false); // false indicates this is not a final save
  };

  const getGradeSummary = () => {
    const levels = data.difficulty_levels || {};
    const activeGrades = Object.entries(levels)
      .filter(([_, level]) => level > 0)
      .map(([grade, level]) => ({
        grade: grade.replace('grade_', ''),
        level
      }));

    return activeGrades.length > 0 
      ? `Active in ${activeGrades.length} grades` 
      : 'No active grades';
  };

  return (
    <div className="space-y-6">
      {/* Summary Section */}
      <div className="bg-gray-50 p-4 rounded-lg">
        <h3 className="text-lg font-medium text-gray-900 mb-2">Difficulty Summary</h3>
        <p className="text-gray-600">{getGradeSummary()}</p>
      </div>

      {/* Grade Selectors */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {[...Array(12)].map((_, index) => {
          const grade = index + 1;
          return (
            <GradeDifficultySelector
              key={grade}
              grade={grade}
              value={data.difficulty_levels?.[`grade_${grade}`] || 0}
              onChange={handleDifficultyChange}
            />
          );
        })}
      </div>

      {/* Quick Actions */}
      <div className="flex flex-wrap gap-2 pt-4 border-t">
        <button
          type="button"  // Add this to prevent form submission
          onClick={() => {
            const newLevels = {};
            for (let i = 1; i <= 12; i++) {
              newLevels[`grade_${i}`] = 0;
            }
            onChange({
              ...data,
              difficulty_levels: newLevels
            }, false);
          }}
          className="px-4 py-2 text-sm border rounded-lg hover:bg-gray-50"
        >
          Reset All
        </button>
{/* 
        <button
          type="button"  // Add this to prevent form submission
          onClick={() => {
            const currentLevel = data.difficulty_levels?.[`grade_6`] || 0;
            const newLevels = {};
            for (let i = 1; i <= 12; i++) {
              newLevels[`grade_${i}`] = currentLevel;
            }
            onChange({
              ...data,
              difficulty_levels: newLevels
            }, false);
          }}
          className="px-4 py-2 text-sm border rounded-lg hover:bg-gray-50"
        >
          Apply Grade 6 Level to All
        </button> */}
      </div>
    </div>
  );
};

export default QuestionDifficultyForm;